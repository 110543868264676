import { floatHourToString, lengthToString } from '../helpers/time';

export const AMENITIES_CATEGORIES = {
    DOGS: 'For dogs',
    ESSENTIAL: 'Essential',
    PEOPLE: 'For people',
    VENUE: 'Venue',
};

export const ENCLOSURE_TYPE = {
    FULLY_FENCED: 'FULLY_FENCED',
    PARTIALLY_FENCED: 'PARTIALLY_FENCED',
    UNFENCED: 'UNFENCED',
};

export const SPOT_ENCLOSURE = {
    FULLY_FENCED: 'Fully Fenced',
    PARTIALLY_FENCED: 'Partially Fenced',
    UNFENCED: 'Unfenced',
};

export const SPOT_PRESENCE_STATE = {
    SOMETIMES: 'Sometimes',
    NO: 'No',
    YES_CANNOT_CHANGE: 'Yes (Always)',
    YES: 'Yes (Always)',
};

export const SPOT_PRIVATE_ENTRY_STATE = {
    SOMETIMES: 'Sometimes',
    NO: 'No',
    YES: 'Yes',
};

// Note: Add values in ascending order!
export const SPOT_FENCE_HEIGHT = {
    FT_NULL: 'N/A',
    FT_LESS_3: '<3 ft',
    FT_3: '3 ft',
    FT_4: '4 ft',
    FT_5: '5 ft',
    FT_6_PLUS: '6+ ft',
};

export const FENCE_HEIGHT_QUICK_FILTER_BTN = {
    FT_LESS_3: '<3 ft ',
    FT_3: '3+ ft ',
    FT_4: '4+ ft ',
    FT_5: '5+ ft ',
    FT_6_PLUS: '6+ ft ',
};

export const HOST_PRESENCE_PREFERENCE = {
    PREFER_MEET: 'I prefer to meet my guests on their first visits',
    PREFER_NOT_MEET: 'I generally do not meet my guests',
    NOT_THERE: 'I am normally not there while my guests are there',
};

export const HOST_PRESENCE_VISIT = {
    PREFER_MEET: 'This host requires to briefly meet their first-time guests.',
    PREFER_NOT_MEET: 'This host generally does not meet their guests.',
    NOT_THERE: 'This host is usually not there while their guests are visiting.',
};

export const PRIVATE_ENTRY_STATE = {
    YES: 'Yes',
    SOMETIMES: 'Sometimes',
    NO: 'No',
};

export const SIZE_UNITS = {
    SQ_FT: 'sq ft',
    ACRES: 'acres',
};

export const SPOT_TAGS = {
    FETCH: 'Fetch',
    HIKE: 'Hike',
    GRASS: 'Grass',
    WATER: 'Water',
    FLAT: 'Flat',
    HILLS: 'Hills',
    AGILITY: 'Agility',
    WOODS: 'Woods',
};

export const TOP_SPOT = {
    repeatScore: 1.2,
    guestNumber: 10,
};

export const CREATE_SPOT_MAP_ZM = 2000; // in meters
export const SPOT_DETAIL_MAP_ZM = 4500; // in meters
export const SPOT_DETAIL_MAP_ZM_MBL = 2500; // in meters
export const LISTING_DEFAULT_MAP_ZM = 25000; // in meters
export const LISTING_DEFAULT_STATE_MAP_ZM = 250000; // in meters
export const LISTING_DEFAULT_MAP_SPAN = 0.15;
export const CIRCLE_RADIUS = 322; // 0.2 miles in meters
export const SPOTS_MIN = 30;
export const SPOTS_PER_PAGE = 12;
export const SPOT_DEFAULT_PRICE = 5;
export const SPOT_MIN_REQ_PHOTOS = 10;

export const SPOT_SIZE_OPTIONS = [
    {
        value: 0.02,
        label: '1000 sq ft',
    },
    {
        value: 0.06,
        label: '2500 sq ft',
    },
    {
        value: 0.11,
        label: '5000 sq ft',
    },
    {
        value: 0.17,
        label: '7500 sq ft',
    },
    {
        value: 0.25,
        label: '.25 acres',
    },
    {
        value: 0.5,
        label: '.5 acres',
    },
    {
        value: 1,
        label: '1 acre',
    },
    {
        value: 2,
        label: '2 acres',
    },
    {
        value: 3,
        label: '3 acres',
    },
    {
        value: 4,
        label: '4 acres',
    },
    {
        value: 5,
        label: '5 acres',
    },
];

export const SPOT_SIZE_OPTIONS_FOR_ALERT = SPOT_SIZE_OPTIONS;

const fillAvailableTime = () => {
    const options = [];
    for (let value = 0; value < 23.5; value += 0.5) {
        options.push({
            value,
            label: floatHourToString(value),
        });
    }
    return options;
};

export const AVAILABLE_TIME = fillAvailableTime();
export const MAX_AVAILABLE_TIME_LENGTH = 8;

const fillAvailableTimeLength = () => {
    const options = [{ value: 0.5, label: lengthToString(0.5) }];
    for (let value = 1; value <= MAX_AVAILABLE_TIME_LENGTH; value += 0.5) {
        options.push({ value, label: lengthToString(value) });
    }
    return options;
};

export const AVAILABLE_TIME_LENGTH = fillAvailableTimeLength();

export const SPOT_SRC = {
    WEB_CALENDAR: 'web_calendar',
    WEB_EMAIL: 'web_email',
    WEB_HOME: 'web_home',
    WEB_MEMBERSHIPS: 'web_memberships',
    WEB_MESSAGE: 'web_message',
    WEB_SEARCH: 'web_search',
    WEB_SPOT: 'web_spot',
    WEB_THANK: 'web_thank',
    WEB_VISIT: 'web_visit',
};

export const SPOT_SRC_MEMBERSHIP = {
    DIRECT_WEB: 'DIRECT_WEB',
    HOMELP_WEB: 'HOMELP_WEB',
    TRIALLANDINGPAGE_WEB: 'TRIALLANDINGPAGE_WEB',
    WEB: 'WEB',
    WEB_CALENDAR: 'WEB_CALENDAR',
    WEB_CHECKOUT: 'WEB_CHECKOUT',
    WEB_DETAIL_BADGE: 'WEB_DETAIL_BADGE',
    WEB_DETAIL_PRICING: 'WEB_DETAIL_PRICING',
    WEB_HOME: 'WEB_HOME',
    WEB_HOST_LINK: 'WEB_HOST_LINK',
    WEB_MEMBERSHIPS: 'WEB_MEMBERSHIPS',
    WEB_REVIEW_BADGE: 'WEB_REVIEW_BADGE',
    WEB_VISIT: 'WEB_VISIT',
};

export const SIGN_UP_SRC = {
    AD_POPUP_WEB: 'AD_POPUP_WEB', // Ad popup
    BOTTOM_SEARCH_WEB: 'BOTTOM_SEARCH_WEB', // Bottom search
    CALENDAR_WEB: 'CALENDAR_WEB', // Calendar
    CHECKOUT_WEB: 'CHECKOUT_WEB', // Checkout
    FAVORITE_SEARCH_WEB: 'FAVORITE_SEARCH_WEB', // Favorite search
    FAVORITE_SPOT_WEB: 'FAVORITE_SPOT_WEB', // Favorite spot
    HOST_BANNER_WEB: 'HOST_BANNER_WEB', // Host banner
    HOST_CTA_WEB: 'HOST_CTA_WEB', // Host CTA
    HOST_LANDING_SHORT_WEB: 'HOST_LANDING_SHORT_WEB',
    INVITE_WEB: 'INVITE_WEB', // Invite
    MEMBERSHIPS_WEB: 'MEMBERSHIPS_WEB',
    MESSAGE_HOST_WEB: 'MESSAGE_HOST_WEB', // Message host
    NAV_WEB: 'NAVMENU_WEB', // Nav menu
    NEWHEADER_WEB: 'NEWHEADER_WEB', // New mobile top bar
    PHOTO_REQUEST_WEB: 'PHOTO_REQUEST_WEB', // Photo request
    REPORT_WEB: 'REPORT_WEB', // Report
    REVIEW_WEB: 'REVIEW_WEB',
    SEARCHPOPUP_WEB: 'SEARCHPOPUP_WEB',
    SEOPOPUP_WEB: 'SEOPOPUP_WEB',
    SNIFFPASS_WEB: 'SNIFFPASS_WEB',
    SPOT_DETAILS_WEB: 'SPOT_DETAILS_WEB',
    HOME_FORM_WEB: 'HOME_WEB', //TODO: Add 'home_form_web' to backend
    FILTERS_WEB: 'FILTERS_WEB',
};

export const CALENDAR_TYPE = {
    WEEK: 'WEEK',
    MONTH: 'MONTH',
};

export const DAY_TYPE = {
    MORNING: 'MORNING',
    AFTERNOON: 'AFTERNOON',
    EVENING: 'EVENING',
};

export const MAX_DOGS = 20;
export const QTYS_SPOT = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
export const LENGTHS_SPOT = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12];

export const CREATE_SPOT_GROUP = {
    DEFAULT: 'DEFAULT',
    WELCOME_PHONE: 'WELCOME_PHONE',
};

export const EXCLUDE_CITIES = ['Manhattan'];

export const UTM_MEDIUM = {
    NATIVE: { type: 'mobile_native' },
    FACEBOOK: { type: 'Facebook', link: 'https://www.facebook.com/sharer/sharer.php?u=' },
    TWITTER: { type: 'Twitter', link: 'https://twitter.com/share?url=' },
    PINTEREST: { type: 'Pinterest', link: 'https://www.pinterest.com/pin/create/button/?url=' },
    LINKEDIN: { type: 'Linkedin', link: 'https://www.linkedin.com/shareArticle?url=' },
    TUMBLR: { type: 'Tumblr', link: 'https://www.tumblr.com/widgets/share/tool?canonicalUrl=' },
    REDDIT: { type: 'Reddit', link: 'https://www.reddit.com/submit?url=' },
    WHATSAPP: { type: 'Whatsapp', link: 'https://web.whatsapp.com/send?text=' },
    MESSENGER: { type: 'Messenger', link: 'https://www.facebook.com/dialog/send?link=' },
    TELEGRAM: { type: 'Telegram', link: 'https://telegram.me/share/url?url=' },
    LINE: { type: 'Line', link: 'https://social-plugins.line.me/lineit/share?url=' },
    MKTG_FB: 'host_mktg_fb',
};

export const SPOT_REVIEWS_SORT_BY = {
    ID: 'ID',
    DATE: 'DATE',
    WITH_COMMENTS: 'WITH_COMMENTS',
    RATING: 'RATING',
};

export const SORT_ORDER = {
    ASC: 'ASC',
    DESC: 'DESC',
};

export const SPOT_REVIEWS_SORT_CONTENT = {
    RECENT: {
        id: 'RECENT',
        text: 'Most recent first',
        sortBy: SPOT_REVIEWS_SORT_BY.DATE,
        sortOrder: SORT_ORDER.DESC,
    },
    COMMENT: {
        id: 'COMMENT',
        text: 'With comments first',
        sortBy: SPOT_REVIEWS_SORT_BY.WITH_COMMENTS,
        sortOrder: SORT_ORDER.DESC,
    },
    LOW_RATING: {
        id: 'LOW_RATING',
        text: 'Lower ratings first',
        sortBy: SPOT_REVIEWS_SORT_BY.RATING,
        sortOrder: SORT_ORDER.ASC,
    },
};

const DEF_STATE_VALUE = 20;

const STATE_VALUE = {
    alabama: 2,
    alaska: 2,
    alberta: 149,
    arizona: 80,
    arkansas: 2,
    'british columbia': 125,
    california: 83,
    colorado: 55,
    connecticut: 25,
    delaware: 11,
    'district of columbia': 25,
    florida: 22,
    georgia: 14,
    hawaii: 0,
    idaho: 12,
    illinois: 22,
    indiana: 11,
    iowa: 9,
    kansas: 52,
    kentucky: 13,
    louisiana: 6,
    maine: 42,
    manitoba: 114,
    maryland: 45,
    massachusetts: 25,
    michigan: 18,
    minnesota: 57,
    mississippi: 2,
    missouri: 11,
    montana: 18,
    nebraska: 20,
    nevada: 45,
    'new brunswick': 3,
    'new hampshire': 49,
    'new jersey': 44,
    'new mexico': 8,
    'new york': 17,
    'north carolina': 35,
    'north dakota': 4,
    'northwest territories': 0,
    'nova scotia': 21,
    nunavut: 0,
    ohio: 22,
    oklahoma: 10,
    ontario: 88,
    oregon: 122,
    pennsylvania: 26,
    'prince edward island': 0,
    quebec: 20,
    'rhode island': 18,
    saskatchewan: 0,
    'south carolina': 9,
    'south dakota': 7,
    tennessee: 22,
    texas: 18,
    utah: 14,
    vermont: 21,
    virginia: 27,
    washington: 143,
    'west virginia': 8,
    wisconsin: 32,
    wyoming: 21,
    yukon: 0,
};

const SIZE_VALUE_COEF = {
    DEFAULT: 1,
    HALF_ACRE: 1.39394,
    MIN_HALF_ACRE: 0.60606,
};

const FENCE_VALUE_COEF = {
    DEFAULT: 1,
    FULLY_FENCED: 1.27273,
    NOT_FULLY_FENCED: 0.30303,
};

const PHOTO_VALUE_COEF = {
    TEN: 2.30303,
    BELOW_TEN: 0.06061,
};

export const getSpotValue = (state = '', size, fence, photoCount = 0) => {
    const stateVal = STATE_VALUE[state] || DEF_STATE_VALUE;
    const sizeVal = size >= 0.5 ? SIZE_VALUE_COEF.HALF_ACRE : size > 0 ? SIZE_VALUE_COEF.MIN_HALF_ACRE : SIZE_VALUE_COEF.DEFAULT;
    const fenceVal =
        fence == null
            ? FENCE_VALUE_COEF.DEFAULT
            : fence == ENCLOSURE_TYPE.FULLY_FENCED
            ? FENCE_VALUE_COEF.FULLY_FENCED
            : FENCE_VALUE_COEF.NOT_FULLY_FENCED;
    const photoVal = photoCount >= 10 ? PHOTO_VALUE_COEF.TEN : PHOTO_VALUE_COEF.BELOW_TEN;
    return (stateVal * sizeVal * fenceVal * photoVal).toFixed(2);
};
